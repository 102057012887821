import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import type { RootState } from 'store'
import { NavigateForward, UpdateUserValue } from 'store/userSetup/action'
import { handleToastMessage } from 'utils/handleToastMesasge'

import handleApiCall from 'api/handleApiCall'

import TellitInput from 'components/form/TellitInput'
import { Header, NextBtn } from './subComponents'
import TellitLoader from 'components/common/TellitLoader'
import UseValidateForm, { type IErrorObj } from 'utils/useFormValidate'

const searchParams = new URLSearchParams(window.location.search)
const override = searchParams.get('override')

const StepOne = (): JSX.Element => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [loading, setLoading] = useState<boolean>(false)
  const [isError, setIsError] = useState<boolean>(false)
  const [creditSafeError, setCreditSafeError] = useState<string>('')
  const [creditSafeNameError, setCreditSafeNameError] = useState<string>('')
  const [errorArray, setErrorArray] = useState<IErrorObj[]>([])
  const formValues = useSelector(
    (state: RootState) => state.userSetup.userValues
  )
  const [canContinue, setCanContinue] = useState<boolean>(false)

  const handleOnChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ): JSX.Element | null => {
    const { name, value } = e.target
    dispatch(UpdateUserValue({ name, value }))
    return null
  }

  const forms = [
    {
      label: t('StepOne.Form.OrganizationNumber.Label'),
      id: 'orgNumber',
      name: 'orgNumber',
      value: formValues.orgNumber,
      type: 'text',
      // placeholder: t('StepOne.Form.OrganizationNumber.Placeholder'),
      required: true,
      onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.value.length > 10 || isNaN(Number(e.target.value))) {
          return
        }
        setCanContinue(e.target.value.length === 10)
        handleOnChange?.(e)
      },
      enableErrorMsg: true,
      validationType: 'organizationNumber',
      errorMsg: creditSafeError
    }
    // ,{
    //   label: t('StepOne.Form.CompanyName.Label'),
    //   id: 'companyName',
    //   name: 'companyName',
    //   value: formValues.companyName,
    //   type: 'text',
    //   // placeholder: t('StepOne.Form.CompanyName.Placeholder'),
    //   required: true,
    //   onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
    //     handleOnChange?.(e)
    //   },
    //   enableErrorMsg: true,
    //   errorMsg: creditSafeNameError
    // }
  ]

  const handleCredisafe = (): void => {
    setLoading(true)
    void handleApiCall({
      urlType: 'checkCreditSafe',
      urlParams: `?orgnr=${formValues.orgNumber}&companyname=${formValues.companyName}&override=${override}`,
      cb: (res, state) => {
        if (state) {
          dispatch(NavigateForward())
        } else {
          setLoading(false)
          // const message = t('StepOne.CreditsafeError.KreditInteGodkand')
          if (res.message !== undefined && res.message !== '') {
            // setCreditSafeError(res.message)
            console.log(res.message)
            setCreditSafeNameError(res.message)
          }
          if (res.popup !== undefined && res.popup !== '') {
            handleToastMessage(res.popup)
          }
        }
      }
    })
  }

  return (
    <>
      <TellitLoader loading={loading} loadingText="Kontroll kreditupplysning">
        {forms.map((formItem, idx) => (
          <TellitInput
            key={`${idx}_formItem`}
            label={formItem.label}
            id={formItem.id}
            name={formItem.name}
            value={formItem.value}
            type={formItem.type}
            // placeholder={formItem.placeholder}
            onChange={formItem.onChange}
            enableErrorMsg={formItem.enableErrorMsg}
            validationType={formItem.validationType}
            setError={(val) => {
              const isFormError = UseValidateForm({
                fieldError: val,
                errorArray,
                name: formItem.name,
                setErrorArray
              })
              setIsError(isFormError)
            }}
            errorMsg={formItem.errorMsg}
            alignCenter
          />
        ))}
        {(creditSafeError !== '' || creditSafeNameError !== '') && (
          <Header className="px-10">
            <>
              {t('StepOne.CreditsafeError.Label1')}
              <br></br>
              <br></br>
              {t('StepOne.CreditsafeError.Label2')}{' '}
              <span className="text-secondary">020-120 28 82</span>.
            </>
          </Header>
        )}

        <div
          className={`self-center ${
            creditSafeError !== '' || creditSafeNameError !== '' ? '' : 'mt-6'
          } flex flex-col xl:flex-row flex-wrap justify-center items-center w-full`}
        >
          {creditSafeError === '' && creditSafeNameError === '' ? (
            <NextBtn
              handleClick={() => {
                handleCredisafe()
              }}
              disabled={
                formValues.orgNumber === '' ||
                // formValues.companyName === '' ||
                isError ||
                !canContinue
              }
            />
          ) : (
            <NextBtn
              label={t('StepOne.Button.BytOrg')}
              type="up"
              handleClick={() => {
                // dispatch(UpdateUserValue({ name: 'orgNumber', value: '' }))
                // dispatch(UpdateUserValue({ name: 'companyName', value: '' }))
                setCreditSafeError('')
                setCreditSafeNameError('')
              }}
            />
          )}
        </div>
      </TellitLoader>
    </>
  )
}

export default StepOne
