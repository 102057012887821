/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { Base64 } from 'js-base64'
import _ from 'lodash'
import type { RootState } from 'store'
import {
  NavigateBackward,
  NavigateForward,
  UpdateUserValue
} from 'store/userSetup/action'
import { type federatedLoginTypes } from 'store/userSetup/types'
import handleApiCall from 'api/handleApiCall'
import TellitCheckbox from 'components/form/TellitCheckbox'
import { BackBtn, NextBtn } from './subComponents'
import TellitLoader from 'components/common/TellitLoader'
import NumberBadge from 'components/common/TellitNumberBadge'
import 'react-toastify/dist/ReactToastify.css'
import CheckPersonNumber from 'components/common/CheckPersonNumber'
import { notNullUndefinedCheck } from 'utils/commonFunctions'
import { handleToastMessage } from 'utils/handleToastMesasge'
import TellitButton from 'components/common/TellitButton'
import BankIdLogo from 'components/BankIdLogo'

const searchParams = new URLSearchParams(window.location.search)
const override = searchParams.get('override')

type ErrorMessageProps = {
  messageArray: string[]
}

const ErrorMessage = ({ messageArray }: ErrorMessageProps): JSX.Element => {
  return (
    <div>
      {messageArray.map((msg, index) => (
        <React.Fragment key={index}>
          {messageArray.length === 1 ? msg : index + 1 + '. ' + msg}
          {index < messageArray.length - 1 && <br />}
        </React.Fragment>
      ))}
    </div>
  )
}

function getRatingWithFallback(): boolean {
  try {
    const raw = localStorage.getItem('RATING')
    // Om inget finns
    if (!raw) return false

    // Försök parsa
    return JSON.parse(raw)
  } catch (err) {
    // Om det inte är giltig JSON
    return false
  }
}

const StepSix = (): JSX.Element => {
  const [loading, setLoading] = useState<boolean>(false)
  const [saveLoading, setSaveLoading] = useState<boolean>(false)
  const [federated, setFederated] = useState<federatedLoginTypes>({})
  const [startPolling, setStartPolling] = useState<boolean>(false)
  const [openBankIDUrl, setOpenBankIDUrl] = useState<string>('')
  const [width, setWidth] = useState<number>(window.innerWidth)

  const selectValues = useSelector(
    (state: RootState) => state.userSetup.cardData
  )

  const { t } = useTranslation()
  const dispatch = useDispatch()
  const formValues = useSelector(
    (state: RootState) => state.userSetup.userValues
  )

  const handleCheckboxChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ): JSX.Element | null => {
    const { name, checked } = e.target
    dispatch(UpdateUserValue({ name, value: checked }))
    return null
  }

  const forms = [
    {
      label: t('StepSix.Condition.One'),
      checked: formValues.confirm1,
      name: 'confirm1'
    },
    {
      label: t('StepSix.Condition.Two'),
      checked: formValues.confirm2,
      name: 'confirm2',
      linkText: t('StepSix.Condition.PdfLInkText')
    }
  ]

  const controller = new AbortController()

  function handleWindowSizeChange(): void {
    setWidth(window.innerWidth)
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange)
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange)
    }
  }, [])

  const isMobile = width <= 768

  const handleSubmit = (): void => {
    // bank id verify
    void handleApiCall({
      urlType: 'federatedLogin',
      urlParams: `?override=${override}`,
      setLoading,
      cb: (res, state) => {
        if (state) {
          setFederated(res?.data)
          setTimeout(() => {
            if (res?.data?.sessionId) {
              setStartPolling(true)
              handleGetSession(res?.data?.sessionId)
            }
          }, 1000)
        } else {
          console.log('error')
          setStartPolling(false)
          setFederated({})
          if (notNullUndefinedCheck(res.message)) {
            handleToastMessage(res.message)
          }
        }
      }
    })
  }

  const getSaveFormValues = (): any => {
    const rating = getRatingWithFallback()
    const form = new FormData()
    // step 1
    form.append('accountname', formValues.companyName.trim())
    // form.append('accountname', 'sl shif test')
    form.append('orgnr', formValues.orgNumber.trim())
    // step 2
    form.append('personal_number', formValues.personNumber.trim())
    form.append('adminfirstname', formValues.name.trim())
    form.append('adminlastname', '') // we collect only name
    form.append('adminnumber', formValues.mobile.trim())
    form.append('adminemail', formValues.email.trim())
    form.append('admin_loggedin', 'yes')
    // mis
    form.append('address', formValues.address.trim())
    form.append('zip', formValues.zip.trim())
    form.append('city', formValues.city.trim())
    form.append('areacode', formValues.selectedNewNumber.trim())
    // step 3
    form.append('newnumber', formValues.telephoneNumber.trim())
    form.append('carrier', formValues.carrier.trim())
    // step 4
    form.append(
      'seconduserfirstname',
      formValues?.users[0]?.firstName.trim() || ''
    )
    form.append(
      'seconduserlastname',
      formValues?.users[0]?.lastName.trim() || ''
    )
    form.append('secondusernumber', formValues?.users[0]?.mobile.trim() || '')
    form.append('seconduseremail', formValues?.users[0]?.email.trim() || '')
    form.append('seconduser_loggedin', 'yes')
    form.append(
      'thirduserfirstname',
      formValues?.users[1]?.firstName.trim() || ''
    )
    form.append(
      'thirduserlastname',
      formValues?.users[1]?.lastName.trim() || ''
    )
    form.append('thirdusernumber', formValues?.users[1]?.mobile.trim() || '')
    form.append('thirduseremail', formValues?.users[1]?.email.trim() || '')
    form.append('thirduser_loggedin', 'yes')
    form.append(
      'fourthuserfirstname',
      formValues?.users[2]?.firstName.trim() || ''
    )
    form.append(
      'fourthuserlastname',
      formValues?.users[2]?.lastName.trim() || ''
    )
    form.append('fourthusernumber', formValues?.users[2]?.mobile.trim() || '')
    form.append('fourthuseremail', formValues?.users[2]?.email.trim() || '')
    form.append('fourthuser_loggedin', 'yes')
    // step 5
    form.append('weekdaysstart', formValues.openTime === 1 ? '8.00' : '')
    form.append('weekdaysend', formValues.openTime === 1 ? '17.00' : '')
    form.append('ringall', formValues.callType === 0 ? 'true' : 'false')
    form.append('weekendsstart', '')
    form.append('weekendsend', '')
    form.append('lunchstart', '')
    form.append('lunchend', '')
    form.append('creditsafeRating', rating.toString())
    const joinedValues = selectValues.numbers
      .map((item) => item.value)
      .join(',')
    form.append('searchednumbers', joinedValues)

    return form
  }

  const gethandleSaveValuesError = (errorObj: any): string[] => {
    const extractMessages: string[] = []

    for (const key in errorObj) {
      if (Object.prototype.hasOwnProperty.call(errorObj, key)) {
        const errorkey = key
        let errorMessage = ''

        const traverse = (errorObj: any): void => {
          for (const key in errorObj) {
            if (errorObj[key] !== null && typeof errorObj[key] === 'object') {
              traverse(errorObj[key])
            } else if (key === 'message') {
              errorMessage = errorObj[key]
              extractMessages.push(`${errorkey} - ${errorMessage}`)
            } else if (errorObj[key] === 'not-found') {
              extractMessages.push(`${key} - ${errorObj[key]}`)
            }
          }
        }

        traverse(errorObj[key])
      }
    }

    return extractMessages
  }

  // console.log(saveController, 'saveController')

  const handleSaveValues = (): void => {
    // save values
    const saveController = new AbortController()
    setSaveLoading(true)
    void handleApiCall({
      urlType: 'save',
      urlParams: `?override=${override}`,
      data: getSaveFormValues(),
      signal: saveController.signal,
      cb: (res, state) => {
        if (state) {
          console.log(res, 'save')
          if (res?.status === 200) {
            dispatch(NavigateForward())
            // remove current progress error arrays
            localStorage.removeItem('errorArray2')
            localStorage.removeItem('errorArray3')
            localStorage.removeItem('errorArray4')
          } else {
            console.log('error', res)
            toast('Error', { type: 'error' })
            setFederated((previous) => {
              return {
                ...previous,
                QRCode: undefined
              }
            })
          }
          setSaveLoading(false)
        } else {
          console.log('error error', res)
          if (res.message) {
            if (!_.isString(res.message)) {
              // console.log('asdasdasdasdMMMM', res.message)
              // response.data.message also
              // const message = {
              //   realm: {
              //     unique: {
              //       message: 'Account realm already in use',
              //       cause: 'slmaneesha.tellit.tech'
              //     }
              //   },
              //   name: {
              //     unique: {
              //       message: 'Account name already in use',
              //       cause: 'sl maneesha'
              //     }
              //   }
              // }

              // const messages: any = {
              //   name: {
              //     minLength: {
              //       value: '',
              //       target: 1,
              //       message: 'String must be at least 1 characters',
              //       cause: ''
              //     }
              //   },
              //   realm: {
              //     unique: {
              //       message: 'Account realm already in use',
              //       cause: 'slmaneesha.tellit.tech'
              //     }
              //   }
              // }

              // response.data only

              // const message = {
              //   '4': {
              //     username: {
              //       pattern: {
              //         value: ' ',
              //         target: '^([+@.\\w_-]+)$',
              //         message: "Failed to match pattern '^([+@.\\w_-]+)$'"
              //       }
              //     },
              //     email: {
              //       wrong_format: {
              //         value: ' ',
              //         message: 'Field is not in the correct format',
              //         format: 'email'
              //       },
              //       minLength: {
              //         value: ' ',
              //         target: 3,
              //         message: 'String must be at least 3 characters',
              //         cause: ' '
              //       }
              //     }
              //   }
              // }
              // const message = {}
              // const erroMessages = gethandleSaveValuesError(message)

              let erroMessages: string[] = []

              if (_.isArray(res?.message)) {
                for (const key in res?.message) {
                  const arrayMessageList = gethandleSaveValuesError(
                    res?.message[key]
                  )
                  erroMessages = [...erroMessages, ...arrayMessageList]
                }
              } else {
                erroMessages = gethandleSaveValuesError(res?.message)
              }

              if (erroMessages && erroMessages.length > 0) {
                toast(<ErrorMessage messageArray={erroMessages} />, {
                  type: 'error'
                })
              } else {
                toast('Något gick tyvärr fel. Försök igen!', { type: 'error' })
              }
            } else {
              handleToastMessage(res.message)
            }
          } else {
            handleToastMessage('Något gick tyvärr fel. Försök igen!')
          }

          setFederated((previous) => {
            return {
              ...previous,
              QRCode: undefined
            }
          })
          setSaveLoading(false)
        }
      }
    })
  }

  const handleGetSession = (sessionId: string): void => {
    void handleApiCall({
      urlType: 'getSession',
      urlParams: `?grandidsession=${sessionId}&onboarding_sign`,
      signal: controller.signal,
      cb: (res, state) => {
        if (state) {
          if (res?.data?.grandidObject) {
            if (res?.data?.grandidObject.message.hintCode !== 'userSign') {
              setOpenBankIDUrl(
                `bankid:///?autostarttoken=${res?.data?.grandidObject.autoStartToken}&redirect=null`
              )
              setFederated({
                ...federated,
                QRCode: res?.data?.grandidObject.QRCode,
                status: ''
              })
            } else {
              setFederated({
                ...federated,
                status: 'pause'
              })
            }
          }

          // cancel api call when success
          if (res?.data?.count) {
            if (res?.data?.status === 'success' && res?.data?.count === 1) {
              setStartPolling(false)
              controller.abort()

              if (res?.data?.personal_number) {
                const comparingNumber = override?.trim()
                  ? override.trim()
                  : formValues.personNumber.toString()
                const isPersonNumberMatch = CheckPersonNumber({
                  personNumber: res?.data?.personal_number.toString(),
                  comparingNumber
                })
                if (!isPersonNumberMatch) {
                  handleToastMessage(t('ErrorMsg.Form.PersonalNumberMismatch'))
                  setFederated({})
                } else {
                  setTimeout(() => {
                    handleSaveValues()
                  }, 1000)
                }
              } else {
                setTimeout(() => {
                  handleSaveValues()
                }, 1000)
              }
            }
          } else {
            if (res?.data?.status === 'success') {
              setStartPolling(false)
              controller.abort()
              if (res?.data?.personal_number) {
                const comparingNumber = override?.trim()
                  ? override.trim()
                  : formValues.personNumber.toString()
                const isPersonNumberMatch = CheckPersonNumber({
                  personNumber: res?.data?.personal_number.toString(),
                  comparingNumber
                })
                if (!isPersonNumberMatch) {
                  handleToastMessage(t('ErrorMsg.Form.PersonalNumberMismatch'))
                  setFederated({})
                } else {
                  setTimeout(() => {
                    handleSaveValues()
                  }, 1000)
                }
              } else {
                setTimeout(() => {
                  handleSaveValues()
                }, 1000)
              }
            }
          }
        } else {
          setStartPolling(false)
          if (notNullUndefinedCheck(res.message)) {
            handleToastMessage(res.message)
          }
          // handleSaveValues()
          setTimeout(() => {
            setFederated((previous) => {
              return {
                ...previous,
                QRCode: undefined
              }
            })
          }, 2000)
          console.log('error')
        }
      }
    })
  }

  useEffect(() => {
    if (startPolling) {
      const pollInterval = 2000 // 3 seconds
      const getSessionInterval = setInterval(() => {
        if (federated?.sessionId != null) {
          handleGetSession(federated?.sessionId)
        }
      }, pollInterval)
      return () => {
        clearInterval(getSessionInterval)
      }
    }
  }, [startPolling])

  return (
    <>
      <TellitLoader
        loading={loading || saveLoading}
        loadingText={saveLoading ? 'Skapar din växel...' : 'Väntar på BankID'}
      >
        {federated?.QRCode === undefined ? (
          <div>
            <div className="my-10 2xl:mx-sp120 flex flex-col gap-8 2xl:mb-32 step-6-margin 2xl:mt-[30px]">
              {forms.map((formItem, idx) => (
                <TellitCheckbox
                  key={`checkbox-${idx}`}
                  checked={formItem.checked}
                  name={formItem.name}
                  label={formItem.label}
                  vilkorText={formItem.linkText}
                  handleOnChange={(e) => {
                    handleCheckboxChange(e)
                  }}
                  customClassName="!border-1 !border-black/50 bg-white checked:!border-white !w-[19px] !h-[19px]"
                />
              ))}
            </div>

            <div className="self-center mt-8 2xl:pt-[56px] flex flex-col md:flex-row flex-wrap justify-between items-center w-full 2xl:px-sp100 step-6-footer">
              <BackBtn
                handleClick={() => {
                  dispatch(NavigateBackward())
                  // handleSubmit()
                }}
              />
              <NextBtn
                label={t('StepSix.Card.Button.Submit')}
                handleClick={() => {
                  handleSubmit()
                  // handleSaveValues()
                }}
                customClass="accept-btn"
                disabled={!formValues.confirm1 || !formValues.confirm2}
              />
            </div>
          </div>
        ) : (
          <>
            <div className="mt-8 lg:px-sp9 step-6-padding">
              <div className="mb-3 flex flex-col flex-wrap justify-center w-full">
                <div className="items-center flex mb-3">
                  <NumberBadge count={1} />
                  <span className="text-black">
                    {t('StepSix.Card.Qr.StepOne')}
                  </span>
                </div>
                <div className="flex items-center mb-3">
                  <NumberBadge count={2} />
                  <span className="text-black">
                    {t('StepSix.Card.Qr.StepTwo')}
                  </span>
                </div>
                <div className="flex items-center mb-3">
                  <NumberBadge count={3} />
                  <span className="text-black">
                    {t('StepSix.Card.Qr.StepThree')}
                  </span>
                </div>
                {federated.status !== 'pause' ? (
                  <>
                    <div
                      className={`mx-auto mt-4`}
                      dangerouslySetInnerHTML={
                        federated.QRCode != null
                          ? { __html: Base64.decode(federated.QRCode) }
                          : { __html: '' }
                      }
                    />
                    {isMobile && (
                      <TellitButton
                        href={openBankIDUrl}
                        disabled={loading}
                        customClass={`disabled:bg-grey disabled:hover:bg-grey bg-secondary group flex items-center flex-row-reverse shadow-buttonSecondary justify-between px-[27px] rounded-4xl lg:h-[56px] hover:bg-primary`}
                        label={t('StepSix.Label.ÖppnaBankID')}
                        onClick={() => {}}
                        icon={<BankIdLogo />}
                      />
                    )}
                  </>
                ) : (
                  <div
                    className={`flex flex-col justify-center items-center h-[20rem] text-black`}
                  >
                    {/* <div>{View}</div> */}
                    <span className="loading loading-spinner loading-lg bg-secondary" />{' '}
                    <br />
                    Logga in med BankId
                  </div>
                )}
              </div>
            </div>
          </>
        )}
      </TellitLoader>
    </>
  )
}

export default StepSix
